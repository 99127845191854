// src/apis.js
import { apiUrl, paymentApiUrl, oidcConfig } from "../utils/config";
import axios from 'axios';

// İlk API için özel bir instance oluştur
const hrApi = axios.create({
  baseURL: apiUrl
});

// İkinci API için başka bir özel instance oluştur
const paymentApi = axios.create({
  baseURL: paymentApiUrl
});

const authApi = axios.create({
    baseURL: oidcConfig.authority
});

function setAuthHeader(token) {
    if(token){
        hrApi.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
        paymentApi.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
        authApi.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
    }else{
        delete hrApi.defaults.headers.common["Authorization"]
        delete paymentApi.defaults.headers.common["Authorization"]
        delete authApi.defaults.headers.common["Authorization"]
    }
}


// Her bir instance'ı ayrı ayrı export edin
export { hrApi, paymentApi, authApi, setAuthHeader };