import React from "react";
import ReactDOM from "react-dom";
import RoutesPath from "./AppRouter";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import i18n from "./localization/i18n";
import PersistAndStore from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { hrApi, paymentApi, authApi } from "./utils/apiConfig";
import userManager from "./utils/userManager";
import { userExpired } from './redux/actions/auth.actions';
import { gtmCode } from "./utils/config";
import TagManager from 'react-gtm-module'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const tagManagerArgs = {
  gtmId: gtmCode
}

TagManager.initialize(tagManagerArgs)

const { store, persistor } = PersistAndStore();
let currentRequestResolve = null;

hrApi.interceptors.request.use( async (request) => {
    
  const user = await userManager.getUser();
  const access_token = user?.access_token;

  if (access_token) {
    request.headers['Authorization'] = `Bearer ${access_token}`; 
  }

  request.headers['Accept-Language'] = localStorage.getItem('i18nLang') === 'en' ? 'en' : 'tr-TR'

  return request;
});

paymentApi.interceptors.request.use( async (request) => {

  const user = await userManager.getUser();
  const access_token = user?.access_token;

  if (access_token) {
    request.headers['Authorization'] = `Bearer ${access_token}`; 
  }

  request.headers['Accept-Language'] = localStorage.getItem('i18nLang') === 'en' ? 'en' : 'tr-TR'

  return request;
});

authApi.interceptors.request.use( request => {

  request.headers['Accept-Language'] = localStorage.getItem('i18nLang') === 'en' ? 'en' : 'tr-TR'

  return request;
});

const responseSuccessHandler = response => {
  if (currentRequestResolve) {
    currentRequestResolve(response);
  }
  return response;
};
const responseErrorHandler = error => {
  if (error?.response?.status === 401) {
    store.dispatch(userExpired())
  }
  if (currentRequestResolve) {
    currentRequestResolve(Promise.reject(error));
  }
  return Promise.reject(error);
}

hrApi.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);
paymentApi.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);
authApi.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

export const setRequestResolver = (resolver) => {
  currentRequestResolve = resolver;
};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RoutesPath />
    </PersistGate>
  </Provider>
  </QueryClientProvider>
  ,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register("serviceWorker.js");
