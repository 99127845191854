import React, { useState } from 'react';
import { Select, Spin, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { setRequestResolver } from '../../index'; 

const { Option } = Select;
const turkishCharacterRegex = keyword => keyword && keyword
  .replace(/[ıİiI]/g, '[ıİiI]')
  .replace(/[şŞsS]/g, '[şŞsS]')
  .replace(/[çÇcC]/g, '[çÇcC]')
  .replace(/[ğĞgG]/g, '[ğĞgG]')
  .replace(/[öÖoO]/g, '[öÖoO]')
  .replace(/[üÜuU]/g, '[üÜuU]');

const DynamicSelectbox = ({ value = [], defaultValue = '', placeholder = '', size='', dropdownClassName = '', disabled = false, optionList = [], onChange = null, onFocus = null, onBlur = null, onDropdownVisibleChange = null, className = '', isMultiple = false, disallowClear = false, fields = { "id": "id", "displayValue": "displayValue" }, orderBy = null, dynamiCreateBtnStatus = false, dynamicCreateBtnCode = null, defaultSpin = true, recallOnFocus = false}) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);

 let valueData, defaultSelectedList;
  if (optionList?.length > 0 && orderBy === null) {
    optionList = optionList?.sort((a, b) => {
      const res = a[fields['displayValue']].toString().localeCompare(b[fields['displayValue']].toString())
      return res
    });
  }

  if (isMultiple) {
    let tempValue = value ? value : [];
    valueData = tempValue?.map(item => item[fields.id]);

    defaultSelectedList = value;

  } else {
    if (value === null || value[fields.id] === null) {
      valueData = null;
      defaultSelectedList = [];
    } else {
      valueData = value[fields.id];
      defaultSelectedList = [value];
    }

  }

  const triggerChange = (changedValue) => {
    if (isMultiple) {
      onChange?.([
        ...changedValue,
      ]);
    } else {
      onChange?.({
        ...changedValue,
      });
    }

  };

  const handleFocus = async () => {
    setLoader(true);
    try {
      await new Promise((resolve) => {
        setRequestResolver(resolve);
        onFocus(); 
      });
    } catch (err) {
    } finally {
      setLoader(false);
      setRequestResolver(null); 
    }
  };


  const onSelectChange = (newId) => {
    if (isMultiple) {
      let newArray = newId.map(item => {
        if (item !== '') {
          const obj = {}
          obj[fields.id] = item;
          obj[fields.displayValue] = optionList.find(row => row[fields.id] === item)[fields.displayValue] || '';
          return obj;
        } else {
          return false
        }
      })
      triggerChange(newArray);
    } else {
      const obj = {};
      obj[fields.id] = newId || '';
      const item = optionList?.find(row => row[fields.id] === newId);
      obj[fields.displayValue] = item ? item[fields.displayValue] : '';
      triggerChange(obj);
    }

  };

  const customFilter = (searchText, options) => ((turkishCharacterRegex(options.children ? options.children : "")).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase()));

  return (
    <>
      <Select
        showSearch
        filterOption={(search, item) => customFilter(search, item)}
        optionFilterProp="children"
        allowClear={isMultiple || disallowClear !== true}
        value={valueData}
        onChange={onSelectChange}
        onFocus={(optionList.length === 0 || recallOnFocus == true) ? handleFocus : null}

        onBlur={onBlur}
        dropdownClassName={dropdownClassName}
        onDropdownVisibleChange={onDropdownVisibleChange}
        className={className}
        mode={isMultiple ? 'multiple' : ''}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        notFoundContent={menu => ("")}
        dropdownRender={menu => (
          <div>

            {optionList?.length > 0 ? menu : ""}
            {dynamiCreateBtnStatus && dynamicCreateBtnCode}
            {
              optionList?.length === 0 && loader && defaultSpin ? 
                <>
                  <Divider className="my-1 mx-0" />
                  <div className="d-flex flex-nowrap pa-2">

                    <Spin></Spin>
                  </div>
                </>
              : 
              optionList?.length === 0 && !dynamiCreateBtnStatus ?
                <>
                  <div className="d-flex flex-nowrap pa-2">
                    <span className="f-16">{t("NO_DATA_AVAILABLE")}</span>
                  </div>
                </>
              :
                <></>
            }
          </div>
        )}
        defaultValue={defaultValue}
      >
        {
          optionList?.length > 0 ? (
            optionList?.map((data, index) => {
              return (
                <Option key={index} value={data[fields.id]} disabled={data.disabled}>{data[fields.displayValue]}</Option>
              )
            })
          ) : (
            defaultSelectedList?.length > 0 ?
              defaultSelectedList?.map((data, index) => {
                return <Option key={index} value={data[fields.id]}>{data[fields.displayValue]}</Option>
              })
              : ("")
          )
        }
      </Select>
    </>
  );
};

export default DynamicSelectbox;