import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


import PrivateLayout from './PrivateLayout';
import SetPassword from './pages/set-password/index';
import ForgotPassword from './pages/forgot-password/index';
import SuccessPasswordChange from './pages/success-password-change/index';
import Signin from './pages/signin/index';
import LoginCallback from './pages/signin/loginCallback';
import ExternalLoginCallback from './pages/signin/externalLoginCallback';
import RefreshTokenCallback from './pages/signin/refreshTokenCallback';
import LogoutCallback from './pages/signin/logoutCallback';
import Secure3DCallback from './pages/checkout/secure3DCallback';
import Health from './pages/health/index';
import ApproveOnMail from './pages/approveOnMail/index'
import ApproveExpenseOnMail from './pages/approveOnExpense/index';
import ApproveAdvanceOnMail from './pages/approveOnAdvance/index';
import ApproveTravelOnMail from './pages/approveOnTravel/index';
import TravelHostOnMail from './pages/travelHostOnMail';
import ApproveOrganizationRegisterMail from './pages/approveOrganizationRegisterMail';
import CheckoutLayout from './CheckoutLayout';
import Signup from './pages/signup';
import TrialExpired from './CheckoutLayout/trialExpired';
import * as Sentry from "@sentry/react";

function RoutesPath(props) {
  const { auth, permissionList, profile } = props;
  const [permissions, setPermissions ] = React.useState(permissionList)
  
  useEffect(()=>{
    setPermissions(permissionList)
  },[JSON.stringify(permissionList)])

  const [sentryInitialized, setSentryInitialized] = useState(false);
  
  useEffect(() => {
    if (!sentryInitialized) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });

      setSentryInitialized(true);
    }
  }, [sentryInitialized]);

  // Test Sentry
  //return(<button type="button" onClick={() => { throw new Error("Sentry Test Error"); }}>Break the world</button>)


  useEffect(() => {

    let properties = {
      'FIRSTNAME': profile.givenName,
      'LASTNAME' : profile.familyName,
    }

    window.addEventListener('HRbrevoLoaded', function() {
      window.sendinblue?.identify(profile?.corporateEmail, properties);
      // https://tracker-doc.brevo.com/docs/track-custom-events
    });
    
  }, [profile?.displayName]); 

  useEffect(()=>{
    if(profile?.displayName){
      window.sendinblue?.track(
        //brevo_event_page_view
        'page_view',
        {'visited' : window.location.pathname}
      )
    }
    
  },[window.location.pathname])


  return (
    <Router>
      <Switch>
        <Route path="/set-password/:token" render={(props) => <SetPassword  {...props} />} exact></Route>
        <Route path="/approveOnMail/:data" render={(props) => <ApproveOnMail  {...props} />} exact></Route>
        <Route path="/approveExpenseOnMail/:data" render={(props) => <ApproveExpenseOnMail  {...props} />} exact></Route>
        <Route path="/approveAdvanceOnMail/:data" render={(props) => <ApproveAdvanceOnMail  {...props} />} exact></Route>
        <Route path="/approveTravelOnMail/:data" render={(props) => <ApproveTravelOnMail  {...props} />} exact></Route>
        <Route path="/approveTravelHostOnMail/:data" render={(props) => <TravelHostOnMail  {...props} />} exact></Route>
        <Route path="/approve-organization-register-mail/" render={(props) => <ApproveOrganizationRegisterMail  {...props} />} exact></Route>
        <Route path="/forgot-password" exact><ForgotPassword /></Route>
        <Route path="/success-password-change" exact><SuccessPasswordChange /></Route>
        <Route path="/signin" exact><Signin /></Route>
        <Route path="/loginCallback" ><LoginCallback /></Route>
        <Route path="/externalLoginCallback" ><ExternalLoginCallback /></Route>
        <Route path="/refreshTokenCallback" ><RefreshTokenCallback /></Route>
        <Route path="/logoutCallback" ><LogoutCallback /></Route>
        <Route path="/secure3DCallback" ><Secure3DCallback /></Route>
        <Route path="/health" exact><Health /></Route>
        <Route path="/signup" exact><Signup /></Route>
        <Route
          path="/prices"
          exact
          render={() => {
            if (!auth?.user?.access_token) {
              return <Redirect to="/signin" />;
            } else {
              return <CheckoutLayout />;
            }
          }}
        />
        <Route
          path="/payment"
          exact
          render={() => {
            if (!auth?.user?.access_token) {
              return <Redirect to="/signin" />;
            } else {
              return <CheckoutLayout />;
            }
          }}
        />
        <Route
          path="/newPackagePayment"
          exact
          render={() => {
            if (!auth?.user?.access_token) {
              return <Redirect to="/signin" />;
            } else {
              return <CheckoutLayout />;
            }
          }}
        />
        <Route
          path="/paymentCompleted/:transactionId"
          exact
          render={() => {
            if (!auth?.user?.access_token) {
              return <Redirect to="/signin" />;
            } else {
              return <CheckoutLayout />;
            }
          }}
        />
        <Route
          path="/"
          render={() => {
            if (!auth?.user?.access_token) {
              return <Redirect to="/signin" />;
            } else if(auth?.user?.access_token && !permissions?.includes('access_authorization')){ 
              return <TrialExpired />
            }else{
              return <PrivateLayout />;
            }
          }}
        />
      </Switch>
    </Router>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    permissionList: state.profile.permissions,
    profile: state.profile
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RoutesPath);