/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask'
import PhoneInput from 'react-phone-input-2';
import emailMask from 'text-mask-addons/dist/emailMask'
import {
    getTimeOffTypesList, getTimeOffInfoByTimeOffType, resetTimeOffInfoNotifications,
    getWorkHourByDate, getTimeOffRequestInfo, resetTimeOffRequestInfo, getTimeOffInfoByTimeOffTypeAndDate,
    resetWorkHourByDate
} from '../../../redux/actions/timeOff.actions'
import { deleteUploadFile, deleteUploadFileResponseReset } from '../../../redux/actions/person.actions';

import { getPersons } from '../../../redux/actions/organization.actions';

import { Row, Col, Input, Form, DatePicker, TimePicker, Button, Radio, ConfigProvider, Upload } from 'antd';
import moment from 'moment';
import { UilTrashAlt } from '@iconscout/react-unicons';
import { FileAltIcon } from '../../../features/Icon/Icons';
import { apiUrl } from "../../../utils/config";
import DynamicSelectbox from "../../dynamicSelectbox/index";
import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicRequestModal from "../../dynamicModal/requestModal";
import { phoneInputConfig, dateFormat, dateFormatWithHours, hoursFormat, localeLang } from "../../../utils/config";

import '../../../styles/person.scss';

function TimeOffRequestForm(props) {
    const { t } = useTranslation();
    const {
        getTimeOffTypesList, timeOffTypesList, getTimeOffInfoByTimeOffType, timeOffInfoByType, timeOffInfoByTypeWarning,
        timeOffInfoByTypeDateWarning, setCreateObject, resetTimeOffInfoNotifications,
        profile, getPersons, persons, form, typeData, auth, getWorkHourByDate, workHourStartStatus, workHourEndStatus, workHourStartByDate,
        workHourStartByDateLoading, workHourEndByDate, getTimeOffInfoByTimeOffTypeAndDate, workHourEndByDateLoading, timeOffRequestInfo,
        timeOffRequestInfoLoading, getTimeOffRequestInfo, resetTimeOffRequestInfo, resetWorkHourByDate, workHourStartEndByDate, workHourStartEndByDateLoading,
        fileUploadList, setFileUploadList, deleteUploadFile, deleteUploadFileResponseReset, deleteFileResponse
    } = props;

    const defaultStartDayType = 0;
    const defaultEndDayType = 2;
    const [timeOffRequestState, setTimeOffRequestState] = useState({
        'timeOffTypeId': { "id": null, "displayValue": "" },
        'StartDateChooseDayType': 1,
        'EndDateChooseDayType': 1,
        'StartDayType': defaultStartDayType,
        'EndDayType': defaultEndDayType,
        'StartDate': null
    });
    const [phoneList, setPhoneList] = useState([]);
    const [newPhoneInput, setnewPhoneInput] = useState('');
    const [isAddBtnDisabled, setisAddBtnDisabled] = useState(true);
    const [timeOffTypes, setTimeOffTypes] = useState([]);
    const [personList, setPersonList] = useState([]);
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })
    const [disableFormState, setDisableFormState] = useState({
        'dayRadioDisableStatus': false,
        'timeStartButtonDisableStatus': false,
        'timeEndButtonDisableStatus': false,
        'timePickerDisableStatus': false,
        'chooseFullDay': false,
    })

    // const [showContactEmailInput, setshowContactEmailInput] = useState(true);

    const organizationResourceId = profile.organization?.resourceId;
    const HRManagerRole = "İK Yöneticisi";
    const AdminRole = "Admin";
    const access_token = auth.user?.access_token;
    let userRolex = auth?.user?.profile?.role || ["Standart Kullanıcı"];
    let userRole = [];
    if (typeof (userRolex) === 'string') {
        let arr = []
        arr.push(userRolex)
        userRole = arr
    } else {
        userRole = userRolex
    }
    const hasPermissionToAddTimeoffForOthers = userRole.some(item => [AdminRole, HRManagerRole].includes(item))
    const getDisabledTime = (start, end, exclude) => {
        const result = [];
        for (let i = start; i <= end; i++) {
            if (!exclude.includes(i)) {
                result.push(i);
            }
        }
        return result;
    }
    const range = (start, end) => {
        const result = [];
        for (let i = start; i <= end; i++) {
            result.push(i);
        }
        return result;
    }
    const getResourceId = (typeId) => {
        let resourceId = timeOffTypesList.filter((item) => { return item.id === typeId })[0]?.resourceId;
        return resourceId
    }
    useEffect(() => {
        if (timeOffRequestState.timeOffTypeId?.id !== null) {
            async function fetchTimeOffType() {
                const timeOffTypeResourceId = await getResourceId(timeOffRequestState.timeOffTypeId?.id);
                
                // setshowContactEmailInput(timeOffTypeResourceId !== 'FH25DW511a6DYU7');
                
                await (getTimeOffInfoByTimeOffType(timeOffTypeResourceId, timeOffRequestState.personResourceId?.id))
            }
            fetchTimeOffType();
        }
    }, [timeOffRequestState.timeOffTypeId?.id, timeOffRequestState.personResourceId?.id])

    useEffect(() => {
        setPersonList(persons);
    }, [persons]);

    useEffect(() => {
        function updateStartDateTime() {
            if (!workHourStartByDateLoading) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                if (workHourStartByDate !== "") {
                    fields['StartDateTime'] = moment(workHourStartByDate, hoursFormat);
                    form.setFieldsValue(fields)
                    setTimeOffRequestState({ ...timeOffRequestState, 'StartDateTime': workHourStartByDate })
                } else {
                    fields['StartDateTime'] = ""
                    form.setFieldsValue(fields)
                    setTimeOffRequestState({ ...timeOffRequestState, 'StartDateTime': "" })
                }
            }
        }
        function updateTimeOffRequestInfo() {
            if (!workHourStartByDateLoading && (moment(timeOffRequestState.StartDate).isValid() && moment(timeOffRequestState.EndDate).isValid())) {
                let infoObj = {};
                if (timeOffRequestState.unitId === 1) {
                    infoObj = {
                        "startDate": moment(timeOffRequestState.StartDate).format(dateFormat),
                        'startDayType': timeOffRequestState.StartDayType,
                        'endDate': moment(timeOffRequestState.EndDate).format(dateFormat),
                        'endDayType': timeOffRequestState.EndDayType,
                        'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        'personResourceId': timeOffRequestState.personResourceId?.id
                    }
                    getTimeOffRequestInfo(infoObj)
                } else {
                    const StartDate = moment(timeOffRequestState.StartDate).format(dateFormat);
                    var startDateWithHours = moment(StartDate + ' ' + workHourStartByDate).format(dateFormatWithHours);

                    const EndDate = moment(timeOffRequestState.EndDate).format(dateFormat);
                    var endDateWithHours = moment(EndDate + ' ' + timeOffRequestState.EndDateTime).format(dateFormatWithHours);

                    infoObj = {
                        "startDate": moment(startDateWithHours).format(dateFormatWithHours),
                        'startDayType': timeOffRequestState.StartDayType,
                        'endDate': moment(endDateWithHours).format(dateFormatWithHours),
                        'endDayType': timeOffRequestState.EndDayType,
                        'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        'personResourceId': timeOffRequestState.personResourceId?.id
                    }
                    getTimeOffRequestInfo(infoObj)
                }
            }
        }
        async function dataUpdate() {
            if (workHourStartStatus.status === "SUCCESS") {
                updateStartDateTime();
                updateTimeOffRequestInfo();
            }
            else if (workHourStartStatus.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': workHourStartStatus.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['StartDate'] = "";
                fields['StartDateTime'] = "";
                form.setFieldsValue(fields)
                setTimeOffRequestState({ ...timeOffRequestState, 'StartDate': null, 'StartDateTime': "" })
                resetWorkHourByDate('start')
            }
        }
        dataUpdate();
    }, [workHourStartStatus])

    useEffect(() => {
        function updateStartDateTime() {
            if (!workHourStartEndByDateLoading) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                if (workHourStartEndByDate !== "") {
                    fields['StartDateTime'] = moment(workHourStartEndByDate.data, hoursFormat);
                    form.setFieldsValue(fields)
                    setTimeOffRequestState({ ...timeOffRequestState, 'StartDateTime': workHourStartEndByDate.data })
                } else {
                    fields['StartDateTime'] = ""
                    form.setFieldsValue(fields)
                    setTimeOffRequestState({ ...timeOffRequestState, 'StartDateTime': "" })
                }
            }
        }
        function checkEndDate() {
            if (!workHourStartEndByDateLoading && (moment(timeOffRequestState.StartDate).isValid() && moment(timeOffRequestState.EndDate).isValid())) {
                getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.EndDate).format(dateFormat), 2, 'end', timeOffRequestState?.personResourceId?.id)
            }
        }
        async function dataUpdate() {
            if (workHourStartEndByDate.status === "SUCCESS") {
                updateStartDateTime();
                checkEndDate();
            }
            else if (workHourStartEndByDate.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': workHourStartEndByDate.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['StartDate'] = "";
                fields['StartDateTime'] = "";
                form.setFieldsValue(fields)
                setTimeOffRequestState({ ...timeOffRequestState, 'StartDate': null, 'StartDateTime': "" })
                resetWorkHourByDate('startEnd')
            }
        }
        dataUpdate();
    }, [workHourStartEndByDate])

    useEffect(() => {
        function updateEndDateTime() {
            if (!workHourEndByDateLoading) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                if (workHourEndByDate !== "") {
                    fields['EndDateTime'] = moment(workHourEndByDate, hoursFormat);
                    form.setFieldsValue(fields)
                    setTimeOffRequestState({ ...timeOffRequestState, 'EndDateTime': workHourEndByDate })
                } else {
                    fields['EndDateTime'] = ""
                    form.setFieldsValue(fields)
                    setTimeOffRequestState({ ...timeOffRequestState, 'EndDateTime': "" })
                }
            }
        }
        function updateTimeOffRequestInfo() {
            const startDate = moment(timeOffRequestState.StartDate);
            const endDate = moment(timeOffRequestState.EndDate);
            const result = endDate.diff(startDate, 'days');
            const StartDate = moment(timeOffRequestState.StartDate).format(dateFormat);
            const startDateWithHours = moment(StartDate + ' ' + timeOffRequestState.StartDateTime).format(dateFormatWithHours);
            const EndDate = moment(timeOffRequestState.EndDate).format(dateFormat);
            const endDateWithHours = moment(EndDate + ' ' + workHourEndByDate).format(dateFormatWithHours);
            let infoObj = {}
            if (result <= 0 && timeOffRequestState.StartDayType === 1) {
                if (timeOffRequestState.unitId === 1) {
                    infoObj = {
                        "startDate": startDateWithHours,
                        'startDayType': timeOffRequestState.StartDayType,
                        'endDate': endDateWithHours,
                        'endDayType': 2,
                        'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        'personResourceId': timeOffRequestState.personResourceId?.id
                    }
                } else {
                    infoObj = {
                        "startDate": startDateWithHours,
                        'startDayType': timeOffRequestState.StartDayType,
                        'endDate': endDateWithHours,
                        'endDayType': 2,
                        'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        'personResourceId': timeOffRequestState.personResourceId?.id
                    }
                }
                getTimeOffRequestInfo(infoObj)
            } else {
                if (!workHourEndByDateLoading && (moment(timeOffRequestState.StartDate).isValid() && moment(timeOffRequestState.EndDate).isValid())) {
                    if (timeOffRequestState.unitId === 3) {
                        infoObj = {
                            "startDate": startDateWithHours,
                            'startDayType': timeOffRequestState.StartDayType,
                            'endDate': endDateWithHours,
                            'endDayType': 1,
                            'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                            'personResourceId': timeOffRequestState.personResourceId?.id
                        }
                    } else {
                        infoObj = {
                            "startDate": moment(timeOffRequestState.StartDate).format(dateFormat),
                            'startDayType': timeOffRequestState.StartDayType,
                            'endDate': moment(timeOffRequestState.EndDate).format(dateFormat),
                            'endDayType': timeOffRequestState.EndDayType,
                            'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                            'personResourceId': timeOffRequestState.personResourceId?.id
                        }
                    }
                    getTimeOffRequestInfo(infoObj)
                }
            }
        }
        async function dataUpdate() {
            if (workHourEndStatus.status === "SUCCESS") {
                updateEndDateTime();
                updateTimeOffRequestInfo();
            } else if (workHourEndStatus.status === 'FAIL') {
                DynamicToastMessage({
                    'key': 'error_key',
                    'title': t('ERROR'),
                    'description': workHourEndStatus.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['EndDate'] = "";
                fields['EndDateTime'] = "";
                form.setFieldsValue(fields)
                setTimeOffRequestState({ ...timeOffRequestState, 'EndDate': null, 'EndDateTime': "" })
                resetWorkHourByDate('end')
            }
        }
        dataUpdate();
    }, [workHourEndStatus])

    const handleValuesChange = (changedObj, all) => {
        let key = Object.keys(changedObj)[0];
        if (key === "personResourceId") {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['timeOffTypeId'] = { "id": null, "displayValue": "" }
            fields['StartDate'] = ''
            fields['EndDate'] = ''
            form.setFieldsValue(fields)
            setTimeOffRequestState({ ...timeOffRequestState, "personResourceId": changedObj["personResourceId"], "timeOffTypeId": { "id": null, "displayValue": "" }, StartDate: null, EndDate: null });
        } else if (key === 'timeOffTypeId') {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['StartDate'] = '';
            fields['EndDate'] = '';
            fields['StartDateChooseDayType'] = 1;
            fields['StartDayType'] = defaultStartDayType;
            fields['StartDateTime'] = '';
            fields['EndDateChooseDayType'] = 1;
            fields['EndDayType'] = defaultEndDayType;
            fields['EndDateTime'] = '';
            form.setFieldsValue(fields);
            let unitId = timeOffTypesList.filter(filterData => filterData.id === changedObj['timeOffTypeId'].id)[0]?.unitId;
            let timeOffTypeDescription = timeOffTypesList.filter(filterData => filterData.id === changedObj['timeOffTypeId'].id)[0]?.description;
            let personResourceId = timeOffRequestState.personResourceId ? timeOffRequestState.personResourceId : { "id": profile.resourceId }
            setTimeOffRequestState({
                ...timeOffRequestState,
                "personResourceId": personResourceId,
                "timeOffTypeId": changedObj['timeOffTypeId'],
                "StartDate": null,
                "EndDate": null,
                "StartDateChooseDayType": 1,
                "StartDayType": defaultStartDayType,
                "StartDateTime": '',
                'EndDateChooseDayType': 1,
                "EndDayType": defaultEndDayType,
                "EndDateTime": '',
                "unitId": unitId,
                "timeOffTypeDescription": timeOffTypeDescription
            });
            setDisableFormState({
                ...disableFormState,
                'dayRadioDisableStatus': false,
                'timeStartButtonDisableStatus': false,
                'timePickerDisableStatus': false,
            });
            resetTimeOffRequestInfo();
        } else if (key === 'StartDate') {
            if (timeOffRequestState.EndDate && changedObj['StartDate'] !== null) {
                if (timeOffRequestState.EndDate !== null) {
                    const startDatex = moment(changedObj['StartDate']);
                    const endDatex = moment(timeOffRequestState.EndDate);
                    const result = endDatex.diff(startDatex, 'days');
                    if (result <= 0) {
                        const fieldsTemp = form.getFieldsValue()
                        const fields = { ...fieldsTemp };
                        if (timeOffRequestState.StartDayType === 1) {
                            fields['EndDayType'] = 2;
                            form.setFieldsValue(fields);
                            setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true, 'chooseFullDay': false })
                            setTimeOffRequestState({ ...timeOffRequestState, "StartDate": changedObj['StartDate'], 'EndDayType': 2 })
                            getWorkHourByDate(organizationResourceId, moment(changedObj['StartDate']).format(dateFormat), timeOffRequestState.StartDayType, 'start', timeOffRequestState?.personResourceId?.id)
                            getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.EndDate).format(dateFormat), 2, 'end', timeOffRequestState?.personResourceId?.id)
                        } else {
                            fields['StartDateChooseDayType'] = 2;
                            fields['EndDateChooseDayType'] = 2;
                            form.setFieldsValue(fields);
                            getWorkHourByDate(organizationResourceId, moment(changedObj['StartDate']).format(dateFormat), timeOffRequestState.StartDayType, 'start', timeOffRequestState?.personResourceId?.id);
                            getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.EndDate).format(dateFormat), timeOffRequestState.EndDayType, 'end', timeOffRequestState?.personResourceId?.id);
                            setTimeOffRequestState({ ...timeOffRequestState, "StartDate": changedObj['StartDate'], "StartDateChooseDayType": 2, "EndDateChooseDayType": 2 });
                            setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': false, 'timeEndButtonDisableStatus': false, 'chooseFullDay': true })
                        }
                    } else {
                        setTimeOffRequestState({ ...timeOffRequestState, "StartDate": changedObj['StartDate'] });
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false, 'chooseFullDay': false })
                        getWorkHourByDate(organizationResourceId, moment(changedObj['StartDate']).format(dateFormat), timeOffRequestState.StartDayType, 'start', timeOffRequestState?.personResourceId?.id)
                    }
                    const getObj = {
                        "TimeOffType_ResourceId": getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        "endDate": moment(timeOffRequestState.EndDate).format(dateFormat),
                        "startDate": moment(changedObj['StartDate']).format(dateFormat),
                        "personResourceId": timeOffRequestState.personResourceId?.id
                    }
                    getTimeOffInfoByTimeOffTypeAndDate(getObj)
                }
            } else if (changedObj['StartDate'] === null) {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['EndDate'] = ''
                fields['StartDateChooseDayType'] = 1
                fields['StartDayType'] = defaultStartDayType;
                form.setFieldsValue(fields);
                setDisableFormState({ ...disableFormState, 'chooseFullDay': false })
                setTimeOffRequestState({ ...timeOffRequestState, "StartDate": changedObj['StartDate'], EndDate: null, "StartDateChooseDayType": 1, 'StartDayType': defaultStartDayType });
            } else {
                if (!timeOffRequestState.StartDayType) {
                    getWorkHourByDate(organizationResourceId, moment(changedObj['StartDate']).format(dateFormat), defaultStartDayType, 'start', timeOffRequestState?.personResourceId?.id);
                }
                setTimeOffRequestState({ ...timeOffRequestState, "StartDate": changedObj['StartDate'] });
            }
        } else if (key === "StartDateChooseDayType") {
            if (changedObj['StartDateChooseDayType'] === 1) {
                setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': true })
                setTimeOffRequestState({ ...timeOffRequestState, "StartDateChooseDayType": changedObj['StartDateChooseDayType'], "StartDayType": defaultStartDayType });
                getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), defaultStartDayType, 'start', timeOffRequestState?.personResourceId?.id);
            } else {
                const fieldsTemp = form.getFieldsValue()
                const fields = { ...fieldsTemp };
                fields['StartDayType'] = defaultStartDayType
                form.setFieldsValue(fields)
                getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), defaultStartDayType, 'start', timeOffRequestState?.personResourceId?.id);
                setTimeOffRequestState({ ...timeOffRequestState, "StartDateChooseDayType": changedObj['StartDateChooseDayType'] });
                setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': false })
            }
        } else if (key === 'StartDayType') {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['StartDateTime'] = ''
            form.setFieldsValue(fields)
            const startDatex = moment(timeOffRequestState.StartDate);
            const endDatex = moment(timeOffRequestState.EndDate);
            const result = endDatex.diff(startDatex, 'days');
            if (changedObj['StartDayType'] === 3) {
                setTimeOffRequestState({ ...timeOffRequestState, "StartDayType": changedObj['StartDayType'], "StartDateTime": '' });
                if (timeOffRequestState.EndDate !== null) {
                    setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false })
                }
                getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), defaultStartDayType, 'start', timeOffRequestState?.personResourceId?.id)
            } else {
                if (timeOffRequestState.EndDate !== null) {
                    if (result <= 0 && changedObj['StartDayType'] === 1) {
                        const fieldsTemp = form.getFieldsValue()
                        const fields = { ...fieldsTemp };
                        fields['EndDayType'] = 2;
                        form.setFieldsValue(fields);
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true })
                        setTimeOffRequestState({ ...timeOffRequestState, 'EndDayType': 2, 'StartDayType': changedObj['StartDayType'] })
                        getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), changedObj['StartDayType'], 'startEnd', timeOffRequestState?.personResourceId?.id)
                    } else {
                        setTimeOffRequestState({ ...timeOffRequestState, "StartDayType": changedObj['StartDayType'], "StartDateTime": '' });
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false })
                        getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), changedObj['StartDayType'], 'start', timeOffRequestState?.personResourceId?.id)
                    }
                } else {
                    setTimeOffRequestState({ ...timeOffRequestState, "StartDayType": changedObj['StartDayType'], "StartDateTime": '' });
                    getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), changedObj['StartDayType'], 'start', timeOffRequestState?.personResourceId?.id)
                }
            }
        } else if (key === 'EndDayType') {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['EndDateTime'] = ''
            form.setFieldsValue(fields)
            setTimeOffRequestState({ ...timeOffRequestState, "EndDayType": changedObj['EndDayType'], "EndDateTime": '' });
            if (changedObj['EndDayType'] === 3) {
                getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.EndDate).format(dateFormat), defaultEndDayType, 'end', timeOffRequestState?.personResourceId?.id)
            } else {
                getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.EndDate).format(dateFormat), changedObj['EndDayType'], 'end', timeOffRequestState?.personResourceId?.id)
            }
        }
        else if (key === "EndDateChooseDayType") {
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['EndDayType'] = defaultEndDayType
            form.setFieldsValue(fields)
            getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.EndDate).format(dateFormat), defaultEndDayType, 'end', timeOffRequestState?.personResourceId?.id);
            setTimeOffRequestState({ ...timeOffRequestState, "EndDateChooseDayType": changedObj['EndDateChooseDayType'], "EndDayType": defaultEndDayType });
        }
        else if (key === 'EndDate') {
            const startDate = moment(timeOffRequestState.StartDate);
            const endDate = moment(changedObj['EndDate']);
            const result = endDate.diff(startDate, 'days');
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            if (timeOffRequestState.StartDate && changedObj['EndDate'] !== null) {
                if (result <= 0) {
                    if (timeOffRequestState.StartDayType === 1) {
                        fields['EndDayType'] = 2;
                        form.setFieldsValue(fields);
                        setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': true, 'chooseFullDay': false });
                        setTimeOffRequestState({ ...timeOffRequestState, 'EndDayType': 2, "EndDate": changedObj['EndDate'] });
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), 2, 'end', timeOffRequestState?.personResourceId?.id);
                    } else {
                        //result === 0 
                        fields['StartDateChooseDayType'] = 2;
                        fields['EndDateChooseDayType'] = 2;
                        form.setFieldsValue(fields);
                        getWorkHourByDate(organizationResourceId, moment(timeOffRequestState.StartDate).format(dateFormat), timeOffRequestState.StartDayType, 'start', timeOffRequestState?.personResourceId?.id);
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), timeOffRequestState.EndDayType, 'end', timeOffRequestState?.personResourceId?.id);
                        setTimeOffRequestState({ ...timeOffRequestState, "EndDate": changedObj['EndDate'], "StartDateChooseDayType": 2, "EndDateChooseDayType": 2 });
                        setDisableFormState({ ...disableFormState, 'timeStartButtonDisableStatus': false, 'timeEndButtonDisableStatus': false, 'chooseFullDay': true })
                    }
                } else {
                    setDisableFormState({ ...disableFormState, 'timeEndButtonDisableStatus': false, 'chooseFullDay': false })
                    if (timeOffRequestState.EndDateChooseDayType === 1) {
                        fields['EndDayType'] = defaultEndDayType
                        form.setFieldsValue(fields);
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), defaultEndDayType, 'end', timeOffRequestState?.personResourceId?.id)
                    } else {
                        getWorkHourByDate(organizationResourceId, moment(changedObj['EndDate']).format(dateFormat), timeOffRequestState.EndDayType, 'end', timeOffRequestState?.personResourceId?.id)
                    }
                    setTimeOffRequestState({ ...timeOffRequestState, "EndDate": changedObj['EndDate'] });
                }
                const getObj = {
                    "TimeOffType_ResourceId": getResourceId(timeOffRequestState.timeOffTypeId?.id),
                    "startDate": moment(timeOffRequestState.StartDate).format(dateFormat),
                    "endDate": moment(changedObj['EndDate']).format(dateFormat),
                    "personResourceId": timeOffRequestState.personResourceId?.id
                }
                getTimeOffInfoByTimeOffTypeAndDate(getObj)
            } else if (changedObj['EndDate'] === null) {
                fields['EndDateChooseDayType'] = 1;
                form.setFieldsValue(fields);
                setTimeOffRequestState({ ...timeOffRequestState, "EndDate": changedObj['EndDate'], "EndDateChooseDayType": 1, 'EndDayType': defaultEndDayType });
            }
        } else if (key === 'EndDateTime') {
            let infoObj = {};
            if (timeOffRequestState.unitId === 1) {
                infoObj = {
                    "startDate": moment(timeOffRequestState.StartDate).format(dateFormat),
                    'startDayType': timeOffRequestState.StartDayType,
                    'endDate': moment(timeOffRequestState.EndDate).format(dateFormat),
                    'endDayType': timeOffRequestState.EndDayType,
                    'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                    'personResourceId': timeOffRequestState.personResourceId?.id
                }
            } else {
                const StartDate = moment(timeOffRequestState.StartDate).format(dateFormat);
                var startDateWithHours = moment(StartDate + ' ' + timeOffRequestState.StartDateTime).format(dateFormatWithHours);

                const EndDate = moment(timeOffRequestState.EndDate).format(dateFormat);
                var endDateTime = moment(changedObj['EndDateTime']).format("HH:mm:ss")
                var endDateWithHours = moment(EndDate + ' ' + endDateTime).format(dateFormatWithHours);
                infoObj = {
                    "startDate": startDateWithHours,
                    'startDayType': timeOffRequestState.StartDayType,
                    'endDate': endDateWithHours,
                    'endDayType': timeOffRequestState.EndDayType,
                    'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                    'personResourceId': timeOffRequestState.personResourceId?.id
                }
            }
            getTimeOffRequestInfo(infoObj)
            setTimeOffRequestState({ ...timeOffRequestState, "EndDateTime": moment(changedObj['EndDateTime']).format("HH:mm:ss") });
        } else if (key === 'StartDateTime') {
            if (timeOffRequestState.EndDate !== null) {
                let infoObj = {};
                if (timeOffRequestState.unitId === 1) {
                    infoObj = {
                        "startDate": moment(timeOffRequestState.StartDate).format(dateFormat),
                        'startDayType': timeOffRequestState.StartDayType,
                        'endDate': moment(timeOffRequestState.EndDate).format(dateFormat),
                        'endDayType': timeOffRequestState.EndDayType,
                        'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        'personResourceId': timeOffRequestState.personResourceId?.id
                    }
                    getTimeOffRequestInfo(infoObj)
                } else {
                    const StartDate = moment(timeOffRequestState.StartDate).format(dateFormat);
                    var startDateTime = moment(changedObj['StartDateTime']).format("HH:mm:ss")
                    var startDateWithHours = moment(StartDate + ' ' + startDateTime).format(dateFormatWithHours);
                    const EndDate = moment(timeOffRequestState.EndDate).format(dateFormat);
                    var endDateWithHours = moment(EndDate + ' ' + timeOffRequestState.EndDateTime).format(dateFormatWithHours);
                    infoObj = {
                        "startDate": startDateWithHours,
                        'startDayType': timeOffRequestState.StartDayType,
                        'endDate': endDateWithHours,
                        'endDayType': timeOffRequestState.EndDayType,
                        'TimeOffTypeResourceId': getResourceId(timeOffRequestState.timeOffTypeId?.id),
                        'personResourceId': timeOffRequestState.personResourceId?.id
                    }
                    getTimeOffRequestInfo(infoObj)
                }
            }
            setTimeOffRequestState({ ...timeOffRequestState, "StartDateTime": moment(changedObj['StartDateTime']).format("HH:mm:ss") });
        } else {
            setTimeOffRequestState({ ...timeOffRequestState, ...changedObj });
        }
    }
    useEffect(() => {
        setTimeOffRequestState({ ...timeOffRequestState, contactPhones: phoneList })
    }, [phoneList])

    useEffect(() => {
        if (typeData) {
            const Obj = { ...timeOffRequestState, "personResourceId": { id: profile.resourceId }, "timeOffTypeId": { "id": typeData.typeId, "displayValue": typeData.typeName }, StartDate: null, EndDate: null }
            setTimeOffRequestState(Obj)
            setTimeOffTypes([{ "id": typeData.typeId, "displayValue": typeData.typeName }])
            form.setFieldsValue(Obj)
        } else {
            const Obj = { ...timeOffRequestState, "personResourceId": "", "timeOffTypeId": { "id": null, "displayValue": "" }, StartDate: null, EndDate: null }
            setTimeOffRequestState(Obj)
            form.setFieldsValue(Obj)
        }
    }, [typeData])

    const handlePhoneListInputChange = (e, index) => {
        const list = [...phoneList];
        list[index] = e;
        setPhoneList(list);
    };
    const handleRemoveClick = index => {
        const list = [...phoneList];
        list.splice(index, 1);
        setPhoneList(list);
    };
    const [isPhoneInputVisible, setisPhoneInputVisible] = useState(true)
    const showPhoneInput = () => {
        setisPhoneInputVisible(true)
    }
    const hidePhoneInput = () => {
        setisPhoneInputVisible(false)
    }
    const handleAddNewRow = () => {
        setPhoneList([...phoneList, newPhoneInput]);
        hidePhoneInput();
        setnewPhoneInput('')
    };
    const handleAddNewRowCancel = () => {
        hidePhoneInput();
        setnewPhoneInput('')
    }
    const handleNewPhoneInputChange = (e) => {
        const val = e;
        setnewPhoneInput(val)
    }
    useEffect(() => {
        if (newPhoneInput.length > 8) {
            setisAddBtnDisabled(false)
        } else {
            setisAddBtnDisabled(true)
        }
    }, [newPhoneInput])

    useEffect(() => {
        const temp = timeOffTypesList.filter((item) => item.isActive === true)?.map((item) => { return { "id": item.id, "displayValue": item.name } })
        setTimeOffTypes(temp)
    }, [timeOffTypesList])
    useEffect(() => {
        setTimeOffRequestState({ ...timeOffRequestState, 'Documents': fileUploadList });
    }, [fileUploadList])
    useEffect(() => {
        setCreateObject(timeOffRequestState)
    }, [timeOffRequestState]);

    const disabledDate = (current) => {
        return current > moment(timeOffRequestState.EndDate)
    }
    const disabledEndDate = (current) => {
        return current < moment(timeOffRequestState.StartDate)
    }
    const disabledDateTime = () => {
        return {
            disabledHours: () => getDisabledTime(0, 23, range(9, 9)),
            disabledMinutes: () => getDisabledTime(0, 60, [0, 0])
        };
    }
    useEffect(() => {
        if (timeOffInfoByTypeWarning.message) {
            DynamicToastMessage({
                'key': 'type_info_warning',
                'title': t('WARNING'),
                'description': timeOffInfoByTypeWarning.message,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['timeOffTypeId'] = { "id": null, "displayValue": "" }
            form.setFieldsValue(fields)
            setTimeOffRequestState({ ...timeOffRequestState, "timeOffTypeId": { "id": null, "displayValue": "" } });
            resetTimeOffInfoNotifications()
        }
    }, [timeOffInfoByTypeWarning])

    useEffect(() => {
        if (timeOffInfoByTypeDateWarning.message) {
            DynamicToastMessage({
                'key': 'type_date_info_warning',
                'title': t('WARNING'),
                'description': timeOffInfoByTypeDateWarning.message,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
            const fieldsTemp = form.getFieldsValue()
            const fields = { ...fieldsTemp };
            fields['EndDate'] = ''
            form.setFieldsValue(fields)
            setTimeOffRequestState({ ...timeOffRequestState, EndDate: null });
            resetTimeOffInfoNotifications()
        }
    }, [timeOffInfoByTypeDateWarning])
    useEffect(() => {
        if (deleteFileResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 'key': 'delete_file_success', 'title': t('SUCCESS'), 'description': deleteFileResponse.message, 'statusType': 'success', 'position': 'bottom-right' })
            const list = [...fileUploadList];
            list.splice(deleteFileResponse.index, 1);
            setFileUploadList(list);
            deleteUploadFileResponseReset();
        } else if (deleteFileResponse.status === 'FAIL') {
            DynamicToastMessage({ 'key': 'delete_file_error', 'title': t('ERROR'), 'description': deleteFileResponse.message, 'statusType': 'error', 'position': 'bottom-right' })
            deleteUploadFileResponseReset();
        }
    }, [deleteFileResponse])
    const viewDocument = (link) => {
        return window.open(link, '_blank', 'noopener,noreferrer')
    }
    const deleteDocumentModal = (documentUrl, documentName, index) => {
        setDeleteModalData({ ...deleteModalData, 'isVisible': true, "index": index, 'documentUrl': documentUrl, 'name': documentName });
    }
    const uploadProgress = {
        name: 'file',
        action: apiUrl + '/expenses/upload/',
        headers: {
            "Authorization": `Bearer ${access_token}`,
            "Accept-Language": localStorage.getItem('i18nLang')
        },
        listType: 'picture',
        accept: ".xlsx,.pdf,.docx,.png,.jpg,.jpeg",
        maxCount: 1,
        showUploadList: { showRemoveIcon: true, removeIcon: <div className='error-message'><span>{t('ERROR')}</span><UilTrashAlt /></div> },
        iconRender: () => {
            return <FileAltIcon />
        },
        onChange(info) {
            if (info.file.status === 'done') {
                DynamicToastMessage({ 'key': 'detail_upload_document_success', 'title': t('SUCCESS'), 'description': info.file.response.message, 'statusType': 'success', 'position': 'bottom-right' })
                var stateData = [...fileUploadList];
                var documentUploadedDate = moment().format(dateFormatWithHours);
                stateData.push({
                    'documentName': info.file.name,
                    'documentUrl': info.file?.response?.data,
                    'uploadDate': documentUploadedDate
                });
                setFileUploadList(stateData);
            } else if (info.file.status === 'error') {
                DynamicToastMessage({ 'key': 'detail_upload_document_fail', 'title': t('ERROR'), 'description': info.file.response.message, 'statusType': 'error', 'position': 'bottom-right' })
            }
        },
        progress: {
            strokeColor: {
                '0%': '#7CC8C8',
                '100%': '#7CC8C8',
            },
            strokeWidth: 8,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    const uploadFile = () => {
        setTimeout(() => {
            document.getElementById("upload-btn").click()
        }, 500);
    }
    return (
        <>
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteUploadFile}
                confirmData={{ 'index': deleteModalData.index, 'documentUrl': deleteModalData?.documentUrl }}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_TIME_OFF_DOCUMENT_MODAL', { "name": deleteModalData?.name })}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                    className="time-off-request-form scrollable-form pad-right-12 p-x-25"
                >
                    {hasPermissionToAddTimeoffForOthers ?
                        <Form.Item name="personResourceId" className="custom-select" label={t('TIMEOFF_SELECTED_PERSON') + " *"} required >
                            <DynamicSelectbox optionList={personList} placeholder={t('PARTNER_SELECT')} onFocus={() => getPersons(organizationResourceId)}></DynamicSelectbox>
                        </Form.Item> : ''
                    }
                    <Row className="align-items-center " >
                        <Col span={timeOffRequestState.timeOffTypeId.id ? 14 : 24}>
                            <Form.Item className="custom-select mb-2" name="timeOffTypeId" label={t('TIME_OFF_REQUEST_REQUIRED_TYPE') + " *"}>
                                <DynamicSelectbox
                                    optionList={timeOffTypes}
                                    onFocus={() => getTimeOffTypesList()}
                                    recallOnFocus={true}
                                    placeholder={t('TIME_OFF_REQUEST_REQUIRED_TYPE')}
                                    disabled={hasPermissionToAddTimeoffForOthers && !timeOffRequestState.personResourceId?.id}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={10} >
                            {timeOffRequestState.timeOffTypeId.id ?
                                <div className="d-flex flex-end pt-5">
                                    {timeOffRequestState.timeOffTypeId.id === 1 ?
                                        <div>
                                            {t('TIME_OFF_REQUEST_ANNUAL_LEAVE_PERIOD', { "timeOffTypeName": timeOffRequestState.timeOffTypeId?.displayValue })} <b> {t('TIME_OFF_REQUEST_DAYS', { "day": timeOffInfoByType.timeOffBalance })}</b><br />
                                            {t('TIME_OFF_REQUEST_TIME_LEAVE_USED')} <b>{t('TIME_OFF_REQUEST_DAYS', { "day": timeOffInfoByType.usedTimeOffBalance })}</b><br />
                                            {t('TIME_OFF_REQUEST_REMAINING_TIME')} <b>{t('TIME_OFF_REQUEST_DAYS', { "day": timeOffInfoByType.remainderTimeOffBalance })} </b><br />
                                        </div>

                                        :
                                        <div>
                                            {t('TIME_OFF_REQUEST_TIME_LEAVE_USED')} <b> {timeOffInfoByType.usedTimeOffBalance}</b><br />
                                        </div>
                                    }
                                </div>
                                : ''
                            }
                        </Col>
                    </Row>
                    <Col span={14}>
                        {(timeOffRequestState.timeOffTypeId.id) && <div className="black f-14 pb-4"> {timeOffRequestState?.timeOffTypeDescription}</div>}
                    </Col>
                    <Row>
                        <Col span={timeOffRequestState.timeOffTypeId.id ? 14 : 24}>
                            <Form.Item name="StartDate" className={timeOffRequestState?.StartDateChooseDayType === 2 ? "mar-0" : ""} label={t('TIME_OFF_REQUEST_START_TIME') + " *"} >
                                <DatePicker
                                    locale={localeLang}
                                    format={"LL"}
                                    disabledDate={disabledDate}
                                    disabledTime={disabledDateTime}
                                    disabled={!timeOffRequestState.timeOffTypeId.id}
                                    hideDisabledOptions={true}
                                    inputReadOnly={true}
                                    showNow={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            {timeOffRequestState.timeOffTypeId.id &&
                                <div className="time-off-choosen-day-type">
                                    <Form.Item name="StartDateChooseDayType" label='' >
                                        <Radio.Group >
                                            <Radio disabled={(timeOffRequestState.StartDate && disableFormState.chooseFullDay)} value={1}>{t('FULL_DAY')}</Radio>
                                            <Radio disabled={!timeOffRequestState.StartDate} value={2}>{t('HALF_DAY')}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            }
                        </Col>
                        {timeOffRequestState.timeOffTypeId.id &&
                            <Col span={24}>
                                {timeOffRequestState?.StartDateChooseDayType === 2 &&
                                    <div className="time-off-choosen-day-button">
                                        <Form.Item name="StartDayType" label='' >
                                            <Radio.Group>
                                                <Radio.Button value={0} disabled={disableFormState.timeStartButtonDisableStatus}>{t('MORNING')}</Radio.Button>
                                                <Radio.Button value={1} disabled={disableFormState.timeStartButtonDisableStatus}>{t('AFTERNOON')}</Radio.Button>
                                                <Radio.Button value={3} disabled={timeOffRequestState.unitId === 1}>{t('SPECIAL_CLOCK')}</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item className="time-off-choosen-start-time-picker" name="StartDateTime" label='' >
                                            <TimePicker
                                                locale={localeLang}
                                                disabled={timeOffRequestState.StartDayType !== 3}
                                                popupClassName='time-off-choosen-start-time-picker-dropdown'
                                                clearText={t('SETTINGS_DELETE')}
                                                format={hoursFormat}
                                                minuteStep={15}
                                                inputReadOnly={true}
                                            />
                                        </Form.Item>
                                    </div>
                                }
                            </Col>
                        }
                    </Row >
                    <Row>
                        <Col span={timeOffRequestState?.StartDate ? 14 : 24}>
                            <Form.Item name="EndDate" className={timeOffRequestState.EndDateChooseDayType === 2 ? "mar-0" : ""} label={t('TIME_OFF_REQUEST_END_TIME') + " *"} >
                                <DatePicker
                                    locale={localeLang}
                                    format={"LL"}
                                    disabledDate={disabledEndDate}
                                    disabledTime={disabledDateTime}
                                    disabled={!timeOffRequestState?.StartDate}
                                    hideDisabledOptions={true}
                                    inputReadOnly={true}
                                    showNow={false}
                                    placeholder=''
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            {timeOffRequestState?.StartDate &&
                                <div className="time-off-choosen-day-type">
                                    <Form.Item name="EndDateChooseDayType" label='' >
                                        <Radio.Group >
                                            <Radio disabled={(timeOffRequestState.EndDate && disableFormState.chooseFullDay)} value={1}>{t('FULL_DAY')}</Radio>
                                            <Radio disabled={!timeOffRequestState.EndDate} value={2}>{t('HALF_DAY')}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div >
                            }
                        </Col >
                        {
                            timeOffRequestState.StartDate &&
                            <Col span={24}>
                                {timeOffRequestState?.EndDateChooseDayType === 2 &&
                                    <div className="time-off-choosen-day-button">
                                        <Form.Item name="EndDayType" label='' >
                                            <Radio.Group >
                                                <Radio.Button value={1} disabled={disableFormState.timeEndButtonDisableStatus}>{t('AFTERNOON')}</Radio.Button>
                                                <Radio.Button value={2} >{t('EVENING')}</Radio.Button>
                                                <Radio.Button value={3} disabled={timeOffRequestState.unitId === 1}>{t('SPECIAL_CLOCK')}</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item className="time-off-choosen-end-time-picker" name="EndDateTime" label='' >
                                            <TimePicker
                                                locale={localeLang}
                                                className="time-off-choosen-end-time-picker"
                                                disabled={timeOffRequestState.EndDayType !== 3}
                                                popupClassName='time-off-choosen-end-time-picker-dropdown'
                                                format={hoursFormat}
                                                minuteStep={15}
                                                inputReadOnly={true} />
                                        </Form.Item>
                                    </div>
                                }
                            </Col>
                        }
                    </Row >
                    {(!timeOffRequestInfoLoading && timeOffRequestInfo?.details?.length > 0 && timeOffRequestState.EndDate) &&
                        <Form.Item name="" label={t('TIME_OFF_DAYS_HOURS')} className="mar-0 pad-0">
                            <div className="time-off-request-info-class">
                                {timeOffRequestInfo.details.map((data) =>
                                    <h3>{data}</h3>
                                )}
                            </div>
                        </Form.Item>
                    }
                    {
                        (!timeOffRequestInfoLoading && timeOffRequestInfo?.details?.length > 0 && timeOffRequestState.EndDate) ?
                            (
                                <div className="pad-27">
                                    <div className="time-off-request-summary-row space-between w-100">
                                        <div className="green-text f-24"> {timeOffRequestInfo.totalInfo}</div>
                                        <div className="green-text f-24"> {timeOffRequestInfo.startDate}</div>
                                    </div>
                                    <Row>
                                        <Col span={timeOffRequestInfo.totalInfo !== "" ? 12 : 0}>
                                            <div className="w-100">{t('TIME_OFF_DURATION')}</div>
                                        </Col>
                                        <Col span={timeOffRequestInfo.totalInfo !== "" ? 12 : 24}>
                                            <div className="w-100 flex-end">{t('TIME_OFF_REQUEST_RETURN_DATE')}</div>
                                        </Col>
                                    </Row>
                                    <div className="pad-6" />
                                    {timeOffRequestInfo?.weekendDetail?.length > 0 && timeOffRequestInfo.weekendDetail.map((data) =>
                                        <div className="w-100 green">{data}</div>
                                    )}
                                </div>
                            ) : ''
                    }
                    <Form.Item name="description" label={timeOffInfoByType.isRequiredDescription ? t('PARTNER_RETURN_DESCRIPTION')+' *':t('PARTNER_RETURN_DESCRIPTION')}>
                        <Input.TextArea className="max-w-100" showCount maxLength={300} />
                    </Form.Item>
                    <Form.Item name="documents" label={timeOffInfoByType.isRequiredDocument? t('TIME_OFF_REQUEST_FILES')+' *' : t('TIME_OFF_REQUEST_FILES')} >
                        <div className="document-container">

                            <div className='w-100 d-flex flex-wrap '>
                                {fileUploadList.map((data, index) => {
                                    return <div key={index} className="d-block pb-2 w-100">
                                        <Row>
                                            <Col span={18}>
                                                <span className='document-name'>{data.documentName}</span>
                                            </Col>
                                            <Col span={6}>
                                                <div className='d-flex pl-4'>
                                                    <Button className="transparent-btn f-16 " onClick={() => viewDocument(data.documentUrl)}>{t('VIEW')}</Button>
                                                    <Button className="transparent-btn f-16 " onClick={() => deleteDocumentModal(data.documentUrl.split("https://ofixdisktest.blob.core.windows.net/hrplan-test/").join(''), data.documentName, index)}>{t('SETTINGS_DELETE')}</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                })}
                                <div className="w-100">
                                    <Upload {...uploadProgress} >
                                        <span id="upload-btn"></span>
                                    </Upload >
                                </div>
                                <div className="w-100">
                                    <Button className='transparent-btn f-16 pad-0' onClick={() => uploadFile()} >{t('UPLOAD_DOCUMENT')}</Button>
                                </div>
                            </div>
                        </div>
                    </Form.Item>
                    
                    {timeOffInfoByType.isAppearContactInfo && (
                        <Form.Item name="groupEmailAddr" label={timeOffInfoByType.isRequiredContactInfo ? t('TIME_OFF_REQUEST_CONTACT_MAIL')+' *':t('TIME_OFF_REQUEST_CONTACT_MAIL')}>
                            <MaskedInput className="ant-input" mask={emailMask} />
                        </Form.Item>
                    )}
                    <Form.Item name="contactPhones" label={timeOffInfoByType.isRequiredContactInfo ? t('TIME_OFF_REQUEST_ENTER_CONTACT_INFORMATION')+' *':t('TIME_OFF_REQUEST_ENTER_CONTACT_INFORMATION')}>
                        {
                            phoneList.map((item, index) => {
                                return (
                                    <Row key={index} style={{ "display": "flex", "alignItems": "center", "marginBottom": "15px" }} >
                                        <Col span={12} >
                                            <PhoneInput
                                                {...phoneInputConfig}
                                                containerClass={null}
                                                value={item}
                                                disableDropdown={true}
                                                onChange={(e) => handlePhoneListInputChange(e, index)}
                                            />
                                        </Col>
                                        <Col offset={1} span={2}>
                                            <Button className="ant-btn ant-btn-link link-text" onClick={() => handleRemoveClick(index)}>{t('SETTINGS_DELETE')}</Button>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                        <div>
                            <div className="link-text my-15" style={{ display: isPhoneInputVisible ? 'none' : 'block' }} onClick={() => showPhoneInput()}>{t('TIME_OFF_REQUEST_NEW_ADD')}</div>
                            <Row style={{ "display": isPhoneInputVisible ? 'flex' : 'none', "alignItems": "center" }}>
                                <Col span={12}>
                                    <PhoneInput
                                        {...phoneInputConfig}
                                        containerClass={null}
                                        onChange={(e) => handleNewPhoneInputChange(e)}
                                        value={newPhoneInput}
                                        disableDropdown={true}
                                    />
                                </Col>
                                <Col offset={1} span={2}>
                                    <Button className="ant-btn primary-btn" onClick={() => handleAddNewRow()} disabled={isAddBtnDisabled}>{t('INVENTORY_ADD_TEXT')}</Button>
                                </Col>
                                <Col offset={1} span={2}>
                                    <Button className="ant-btn " onClick={() => handleAddNewRowCancel()}>{t('INVENTORY_CANCEL')}</Button>
                                </Col>

                            </Row>
                        </div>
                    </Form.Item >
                </Form >
            </ConfigProvider >
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        persons: state.organization.persons,
        profile: state.profile,
        timeOffInfoByType: state.timeOff.timeOffInfoByType,
        timeOffInfoByTypeDateWarning: state.timeOff.timeOffInfoByTypeDateWarning,
        timeOffInfoByTypeWarning: state.timeOff.timeOffInfoByTypeWarning,
        timeOffTypesList: state.timeOff.timeOffTypes,
        timeOffTypesLoading: state.timeOff.timeOffTypesLoading,
        auth: state.auth,
        timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus,
        workHourStartByDate: state.timeOff.workHourStartByDate.data,
        workHourStartStatus: state.timeOff.workHourStartByDate,
        workHourStartByDateLoading: state.timeOff.workHourStartByDateLoading,
        workHourEndByDate: state.timeOff.workHourEndByDate.data,
        workHourEndStatus: state.timeOff.workHourEndByDate,
        workHourEndByDateLoading: state.timeOff.workHourEndByDateLoading,
        timeOffRequestInfo: state.timeOff.timeOffRequestInfo,
        timeOffRequestInfoLoading: state.timeOff.timeOffRequestInfoLoading,
        workHourStartEndByDate: state.timeOff.workHourStartEndByDate,
        workHourStartEndByDateLoading: state.timeOff.workHourStartEndByDateLoading,
        deleteFileResponse: state.person.deleteFileResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTimeOffInfoByTimeOffTypeAndDate: (resourceId) => { dispatch(getTimeOffInfoByTimeOffTypeAndDate(resourceId)) },
        getTimeOffInfoByTimeOffType: (id, pid) => { dispatch(getTimeOffInfoByTimeOffType(id, pid)) },
        getTimeOffTypesList: () => { dispatch(getTimeOffTypesList()) },
        resetTimeOffInfoNotifications: () => { dispatch(resetTimeOffInfoNotifications()) },
        getPersons: (props) => { dispatch(getPersons(props)) },
        getWorkHourByDate: (organizationResourceId, date, dayType, type, personId) => { dispatch(getWorkHourByDate(organizationResourceId, date, dayType, type, personId)) },
        getTimeOffRequestInfo: (data) => { dispatch(getTimeOffRequestInfo(data)) },
        resetTimeOffRequestInfo: () => { dispatch(resetTimeOffRequestInfo()) },
        resetWorkHourByDate: (status) => { dispatch(resetWorkHourByDate(status)) },
        deleteUploadFile: (obj) => { dispatch(deleteUploadFile(obj)) },
        deleteUploadFileResponseReset: () => { dispatch(deleteUploadFileResponseReset()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffRequestForm);