import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAdvanceCreateDrawer, createAdvance, resetCreateAdvanceResponse } from '../../../redux/actions/advance.actions';

import { Col, Row, Button, Steps, List } from 'antd';
import { UilMultiply } from '@iconscout/react-unicons';
import moment from 'moment';
import 'moment/locale/tr';

import Successful from "../../successful";
import CreateAdvanceRequestsTab from './create_advance_requests_tab';
import DynamicRequestModal from '../../dynamicModal/requestModal';
import DynamicToastMessage from '../../dynamicToastMessage/index';

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/left-step.scss';
import '../../../styles/table.scss';

function CreateAdvanceTabs(props) {

    const { createAdvance, setAdvanceCreateDrawer, createAdvanceRequests, resetCreateAdvanceResponse, page, auth } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [createAdvanceObj, setCreateAdvanceObj] = useState({});
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false });
    const { Step } = Steps;
    const { t } = useTranslation();
    const HRManagerRole = "İK Yöneticisi";
    const AdminRole = "Admin";
    let userRolex = auth?.user?.profile?.role || ["Standart Kullanıcı"];
    let userRole = [];
   
    if (typeof (userRolex) === 'string') {
        let arr = [];
        arr.push(userRolex);
        userRole = arr;
    } else {
        userRole = userRolex;
    }
    const hasPermissionToAddAdvanceForOthers = userRole.some(item => [AdminRole, HRManagerRole].includes(item))

    const [isCreateLoading, setisCreateLoading] = useState(false);

    useEffect(() => {
        if (createAdvanceRequests.status === 'SUCCESS') {
            setCurrentTab(2);
            resetCreateAdvanceResponse()
        }
        else if (createAdvanceRequests.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': createAdvanceRequests.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateAdvanceResponse()
        }
    }, [createAdvanceRequests])


    function prevButton() {
        setCurrentTab(currentTab - 1);
    }
    function closeDrawerControl() {
        if ((createAdvanceObj.AdvanceNeedDate || createAdvanceObj.Amounts?.length > 0 || createAdvanceObj.Description || createAdvanceObj.Notes || createAdvanceObj.RelatedTravelIdx || createAdvanceObj.RequestCreditCard === true) && currentTab === 1) {
            setConfirmModalData({ "isVisible": true })
        } else {
            closeAddModal()
            setCurrentTab(1);
        }
    }
    function closeAddModal() {
        setCurrentTab(1);
        setAdvanceCreateDrawer(false)
        setCreateAdvanceObj({});
    }
    function createNewOne() {
        setCurrentTab(1);
        setCreateAdvanceObj({});
    }

    const MergeObjects = (obj1, obj2) => {
        for (var i in obj2) {
            try {
                if (obj2[i].constructor == Object) {
                    obj1[i] = MergeObjects(obj1[i], obj2[i]);
                } else {
                    obj1[i] = obj2[i];
                }
            } catch (e) {
                obj1[i] = obj2[i];
            }
        }
        return obj1;
    }

    const setCreateObject = (obj) => {
        const mergedObj = MergeObjects(createAdvanceObj, obj)
        setCreateAdvanceObj(mergedObj)

    }
    const checkRequiredFields = (nextFunc) => {
        let hasError = false
        let fields = []
        if (!createAdvanceObj.AdvanceNeedDate || createAdvanceObj.AdvanceNeedDate === "" || createAdvanceObj.AdvanceNeedDate === null) {
            hasError = true
            fields.push(t('PARTNER_NEED_DATE'))
        }
        if(hasPermissionToAddAdvanceForOthers && createAdvanceObj?.personResourceId?.id === ""){
            hasError = true
            fields.push(t('ADVANCE_SELECTED_PERSON'))
        }
        if (createAdvanceObj.Amounts.length > 0) {
            createAdvanceObj.Amounts.map((data) => {
                if ((!data.Amount || data.Amount === "")) {
                    hasError = true;
                    fields.push(t('ADVANCE_AMOUNT'))
                }
                if (!data.CurrencyId) {
                    hasError = true;
                    fields.push(t('PARTNER_CURRENCY'))
                }
            })
        } else {
            hasError = true
            fields.push(t('ADVANCE_AMOUNT'))
        }
        if (!createAdvanceObj?.AdvanceTypes || createAdvanceObj?.AdvanceTypes === null) {
            hasError = true
            fields.push(t('CHOOSE_ADVANCE_TYPE'))
        }
        if ((createAdvanceObj?.AdvanceTypes === 2) && (!createAdvanceObj?.RepaymentType || createAdvanceObj?.RepaymentType?.id === null || createAdvanceObj?.RepaymentType?.id === "")) {
            hasError = true
            fields.push(t('FORM_OF_REPAYMENT'))
        }
        if ((createAdvanceObj?.RepaymentType?.id === 1) && (!createAdvanceObj?.NumberOfInstallments || createAdvanceObj?.NumberOfInstallments?.id === null || createAdvanceObj?.NumberOfInstallments?.id === "")) {
            hasError = true
            fields.push(t('NUMBER_OF_INSTALLMENTS'))
        }
        if ((createAdvanceObj?.RepaymentType?.id === 1) && (!createAdvanceObj.FirstInstallmentPaymentMonth || createAdvanceObj.FirstInstallmentPaymentMonth === "" || createAdvanceObj.FirstInstallmentPaymentMonth === null)) {
            hasError = true
            fields.push(t('FIRST_INSTALLMENT_PAYMENT_MONTH'))
        }
        if (!hasError) {
            nextFunc()
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleCreateAdvanceRequests = () => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        const data = {
            'personResourceId': createAdvanceObj?.personResourceId?.id ? createAdvanceObj?.personResourceId?.id : null,
            'AdvanceNeedDate': createAdvanceObj.AdvanceNeedDate,
            'Amounts': createAdvanceObj.Amounts,
            'AdvanceTypeId': 1,
            'Description': createAdvanceObj.Description ? createAdvanceObj.Description : '',
            'Notes': createAdvanceObj.Notes ? createAdvanceObj.Notes : '',
            'RequestCreditCard': createAdvanceObj.RequestCreditCard,
            'RelatedTravelIdx': createAdvanceObj.RelatedTravelIdx?.id ? createAdvanceObj.RelatedTravelIdx?.id : '',
            'AdvanceMasterTypeId': createAdvanceObj?.AdvanceTypes,
            'ReturnPaymentTypeId': createAdvanceObj?.RepaymentType?.id,
            'InstallmentCount': createAdvanceObj?.NumberOfInstallments?.id,
            'FirstPaymentMount': createAdvanceObj?.FirstInstallmentPaymentMonth ? moment(createAdvanceObj.FirstInstallmentPaymentMonth).format('MMMM') : null,
        }
        checkRequiredFields(() => createAdvance(data))
    }

    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="table-details">
                <div className="add-global-close-icon">
                    <UilMultiply onClick={() => closeDrawerControl()} />
                </div>
                {currentTab !== 2 ?
                    <React.Fragment>
                        <Col offset={6} span={16}>
                            <div className="add-global-draw-title flex-start">
                                <h1>{t('ADVANCE_CREATE_REQUEST')}</h1>
                            </div>
                        </Col>
                        <div className="w-100 ">
                            <Row>
                                <Col span={6}>
                                    <Steps className="left-step" direction="vertical" size="middle" current={currentTab - 1}>
                                        <Step title={t('HOMEPAGE_CREATE_REQUEST')} description="" />
                                        <Step title={t('DEPARTMENT_COMPLETE')} description="" />
                                    </Steps>
                                </Col>
                                <Col span={17}>
                                    <CreateAdvanceRequestsTab 
                                    setCreateObject={setCreateObject} 
                                    hasPermissionToAddAdvanceForOthers={hasPermissionToAddAdvanceForOthers} 
                                    />
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                    :
                    <Successful message={t('ADVANCE_REQUEST_SUCCESS')} />
                }
            </div>
            <div className="add-user-draw-footer">
                {currentTab < 2 ?
                    <Button onClick={() => closeDrawerControl()} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                {/* clear state */}
                <div className="add-user-draw-footer-end">
                    {currentTab > 1 && currentTab < 2 ?
                        <Button onClick={() => prevButton()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                        :
                        ''
                    }
                    {currentTab === 2 &&
                        <Button onClick={() => createNewOne()} className="pad-0 w-200 h-40 f-14 font-w-700 white-btn">{t('ADVANCE_CREATE_REQUEST')}</Button>
                        //clear state
                    }
                    <div className="pad-left-24" />
                    {currentTab < 2 ?
                        currentTab === 1 &&
                        <Button onClick={() => handleCreateAdvanceRequests()} className="w-200 h-40 f-14 font-w-700 primary-btn ">{t('ADVANCE_CREATE_REQUEST')}</Button>
                        :
                        <Button onClick={() => closeAddModal()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{page ? t('RETURN_HOMEPAGE') : t('INVENTORY_RETURN_LIST')}</Button>
                        //clear state
                    }
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        createAdvanceRequests: state.advance.createAdvanceRequests,
        auth: state.auth
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAdvanceCreateDrawer: (status) => { dispatch(setAdvanceCreateDrawer(status)) },
        createAdvance: (data) => { dispatch(createAdvance(data)) },
        resetCreateAdvanceResponse: () => { dispatch(resetCreateAdvanceResponse()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAdvanceTabs);
